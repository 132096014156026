import type { ConfirmDialogChoice, ConfirmDialogLabel, ErrorDialogData } from '~/types'

interface ModalDialogProps {
  isOpen: boolean;
  disableEscapeClose?: boolean;
  type: 'modal' | 'drawer' | 'side';
  side?: 'left' | 'right';
  transparent?: boolean;
}

export const createOrganisationDialog = reactive({
  isOpen: false,
  disableEscapeClose: false,
  type: 'modal',  // Default type
  transparent: false,
  side: 'right', // Default side
  open(props: ModalDialogProps) {
    Object.assign(this, props);
    this.isOpen = true;
  },
  close() {
    this.isOpen = false;
  },
});

export const confirmDialogChoice = ref<ConfirmDialogChoice>()
export const confirmDialogLabel = ref<ConfirmDialogLabel>()
export const errorDialogData = ref<ErrorDialogData>()

export const isSignInDialogOpen = ref(false)
export const isSignUpDialogOpen = ref(false)
export const isConfirmDialogOpen = ref(false)
export const isErrorDialogOpen = ref(false)



export function openSignInDialog() {
  isSignInDialogOpen.value = true
}

export function closeSignInDialog() {
  isSignInDialogOpen.value = false
}

export function openSignUpDialog() {
  isSignUpDialogOpen.value = true
}

export function closeSignUpDialog() {
  isSignUpDialogOpen.value = false
}

export async function openConfirmDialog(label: ConfirmDialogLabel | string): Promise<ConfirmDialogChoice> {
  confirmDialogLabel.value = typeof label === 'string' ? { title: label } : label
  confirmDialogChoice.value = undefined
  isConfirmDialogOpen.value = true

  await until(isConfirmDialogOpen).toBe(false)

  return confirmDialogChoice.value!
}

export function closeConfirmDialog(choice: ConfirmDialogChoice) {
  confirmDialogChoice.value = choice
  isConfirmDialogOpen.value = false
}

export async function openErrorDialog(data: ErrorDialogData) {
  errorDialogData.value = data
  isErrorDialogOpen.value = true

  await until(isErrorDialogOpen).toBe(false)
}

export function closeErrorDialog() {
  isErrorDialogOpen.value = false
}

export function closeMediaPreview() {
  history.back()
}